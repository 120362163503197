import * as React from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import joinClasses from '/src/functions/joinClasses'
import './styles.scss'

const SectionHeader = loadable(() => import('/src/components/Structure/Section/SectionHeader'))

const Section = ({ id, variant, size, titleArea, className, style, children, itemProp }) => {
    const containerClasses = [
        'c-section',
        ...(variant ? [`c-section--${variant}`] : []),
        ...(size ? [`c-section--${size}`] : []),
        ...(className ? [className] : [])
    ]

    const contentClasses = [
        'c-section__content',
        `c-section__content--${titleArea.titlePosition}`,
        ...(!titleArea.addTitleArea || titleArea.titlePosition === 'inline' ? ['c-section__content--no-spacing'] : [])
    ]

    const concatenatedContainerClasses = joinClasses(containerClasses)
    const concatenatedContentClasses = joinClasses(contentClasses)

    return (
        <section id={id} className={concatenatedContainerClasses} style={style} itemProp={itemProp}>
            <div className="container-lg">
                {titleArea.addTitleArea && titleArea.titlePosition === 'centered' && (
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <SectionHeader variant={'centered'} titleArea={titleArea} />
                        </div>
                    </div>
                )}

                <div className={concatenatedContentClasses}>{children}</div>
            </div>
        </section>
    )
}

Section.propTypes = {
    /**
     * Optional anchor ID
     */
    id: PropTypes.string,
    /**
     * Specify a different style variant
     */
    variant: PropTypes.oneOf(['light', 'dark', 'alt', 'gradient', 'other']),
    /**
     * Specify a different size
     */
    size: PropTypes.oneOf(['reg', 'sm', 'ty']),
    /**
     * Data
     */
    titleArea: PropTypes.shape({
        addTitleArea: PropTypes.bool,
        titlePosition: PropTypes.oneOf(['inline', 'centered']),
        headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
        title: PropTypes.string,
        description: PropTypes.string
    }),
    /**
     * Optional CSS classes
     */
    className: PropTypes.string,
    /**
     * Optional additional styles (USE SPARINGLY)
     */
    style: PropTypes.object,
    /**
     * Inner child elements
     */
    children: PropTypes.node.isRequired,
    /**
     * Optional itemProp
     */
    itemProp: PropTypes.string
}

Section.defaultProps = {
    variant: 'light',
    size: 'reg',
    titleArea: {
        addTitleArea: false,
        titlePosition: 'centered',
        headingLevel: 'h2'
    },
    children: '<p>Section content goes here</p>'
}

export default Section
